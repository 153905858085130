import React, { useState, useEffect, useRef, useCallback } from 'react'
import { Cpu, BarChart, MessageSquare, Puzzle, Lightbulb, Shield, Users, Mail, Linkedin, Send, Instagram } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import Botur from './Images/Botur.png' 
import Asadullo from './Images/Asadullo2.png';
import logo1 from './Images/7.png'
import emailjs from '@emailjs/browser';

/*const testimonials = [
  {
    name: "Kent Wilson",
    role: "COO of RV SnapPad",
    quote: "Essent AI's solutions helped us reconnect with our customers in meaningful ways. The insights were invaluable!",
  },
  {
    name: "Jane Doe",
    role: "CEO of E-Commerce Retailer",
    quote: "The AI-driven recommendation engine significantly boosted our sales and customer satisfaction.",
  },
  {
    name: "John Smith",
    role: "CTO of Financial Services Firm",
    quote: "The workflow automation solution provided by Essent AI has transformed our operations, saving us both time and money.",
  },
]

const caseStudies = [
  {
    id: 1,
    title: "Enhancing Customer Engagement with AI-Powered Solutions",
    client: "RV SnapPad",
    thumbnail: "/placeholder.svg?height=200&width=300",
    logo: "/placeholder.svg?height=50&width=100",
    overview: "Revamped customer re-engagement strategy using AI, leading to a 25% increase in returning customers.",
    challenge: "RV SnapPad wanted to boost customer re-engagement and reduce churn rates among their customer base.",
    solution: "Essent AI implemented an AI-driven customer engagement model that utilized predictive analytics and personalized email campaigns to target customers based on their browsing and purchasing behavior.",
    results: "The campaign led to a 25% increase in returning customers and a 15% rise in overall sales within the first three months. The AI model also provided actionable insights that helped RV SnapPad better understand customer needs and preferences.",
  },
  {
    id: 2,
    title: "Boosting E-commerce Sales with AI Automation",
    client: "E-Commerce Retailer",
    thumbnail: "/placeholder.svg?height=200&width=300",
    logo: "/placeholder.svg?height=50&width=100",
    overview: "Increased sales by 30% through automated marketing and recommendation engines.",
  },
  {
    id: 3,
    title: "Streamlining Operations with AI-Driven Workflow Automation",
    client: "Financial Services Firm",
    thumbnail: "/placeholder.svg?height=200&width=300",
    logo: "/placeholder.svg?height=50&width=100",
    overview: "Reduced operational costs by 40% with custom AI workflow automation.",
  },
]
*/
const GlobalStyle = () => (
    <style jsx global>{`
      html, body {
        margin: 0;
        padding: 0;
        overflow-x: hidden;
      }
    `}</style>
  )
  const founders = [
    {
      name: "Boturjon Shukurov",
      role: "CEO & Co-Founder",
      bio: "Mr. Boturjon has 2.5 years of experience in digital marketing, specializing in Facebook and Google ad campaigns. He has generated over $10,000 in gross revenue and achieved a 3:1 ROAS through affiliate marketing for his retail brand.",
      image: Botur
    },
    {
      name: "Asadullo Kurbonov",
      role: "COO & Co-Founder",
      bio: "Co-founded Essent AI in late 2023. With his expert skills in sales and marketing, he aims to educate business owners on how they can utilize and thrive through AI integration in their businesses.",
      image: Asadullo
    }
  ];

  const values = [
    {
      icon: <Lightbulb className="w-8 h-8" />,
      title: "Innovation",
      description: "Constantly pushing the boundaries of AI technology."
    },
    {
      icon: <Shield className="w-8 h-8" />,
      title: "Integrity",
      description: "Upholding the highest ethical standards in AI development and deployment."
    },
    {
      icon: <Users className="w-8 h-8" />,
      title: "Client-Centric Approach",
      description: "Tailoring our solutions to meet the unique needs of each client."
    }
  ];
  



const EssentAISinglePage = () => {
  const [isScrolled, setIsScrolled] = useState(false)
 // const [activeTestimonial, setActiveTestimonial] = useState(0)
  const [activeCaseStudy, setActiveCaseStudy] = useState(null)
  const [formData, setFormData] = useState({
    from_name: '',
    from_email: '',
    from_company: '',
    message: ''
  })
  

  const [isOpen, setIsOpen] = useState(false)


  const closeModal = () => setIsOpen(false);

  const contactRef = useRef(null)
  //const [formSubmitted, setFormSubmitted] = useState(false)
  const form = useRef();

  const sendEmail = (e) => {
    e.preventDefault();

    // Replace with your EmailJS service, template IDs, and public key
    const serviceID = 'service_ik8sxkv';
    const templateID = 'template_v6nky6w';
    const publicKey = 'lk4RZ_-9tNvwhdcrf';
    emailjs
      .sendForm(serviceID, templateID, form.current, publicKey)
      .then(
        () => {
          console.log('SUCCESS!');
          setIsOpen(true);
        // Reset the form
   setFormData({
    from_name: '',
    from_email: '',
    from_company: '',
    message: ''
   })
        
        },
        (error) => {
          console.log('FAILED...', error.text);
          alert('Oops! Something went wrong. Please try again.');
        }
      );

    
  };
  

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };
  
  

  const homeRef = useRef(null)
  const servicesRef = useRef(null)
  const aboutRef = useRef(null)
 // const successStoriesRef = useRef(null)


  const handleScroll = useCallback(() => {
    setIsScrolled(window.scrollY > 50)
  }, [])

  useEffect(() => {
    window.addEventListener('scroll', handleScroll)
    return () => window.removeEventListener('scroll', handleScroll)
  }, [handleScroll])

  const scrollTo = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' })
  }

 

  /*const handleSubmit = (e) => {
    e.preventDefault()
    console.log('Form submitted:', formData)
    setFormSubmitted(true)
  }
*/
  const NavLink = ({ children, onClick }) => (
    <button
      onClick={onClick}
      className="text-gray-300 hover:text-purple-400 transition-colors duration-300 cursor-pointer"
    >
      {children}
    </button>
  )

  const Button = React.memo(({ children, onClick, className, type = "button" }) => (
    <button
      type={type}
      onClick={onClick}
      className={`px-4 py-2 rounded ${className}`}
    >
      {children}
    </button>
  ))

  

  

  const ServiceCard = React.memo(({ icon, title, description }) => (
    <div className="bg-gray-900 p-6 rounded-lg border border-gray-700 hover:border-purple-400 transition-colors duration-300 group cursor-pointer">
      <div className="mb-4 text-purple-400 group-hover:text-purple-300 transition-colors duration-300">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-2 text-purple-400 group-hover:text-purple-300 transition-colors duration-300">{title}</h3>
      <p className="text-gray-400">{description}</p>
    </div>
  ))

 /* const ValueCard = React.memo(({ icon, title, description }) => (
    <div className="bg-gray-800 p-6 rounded-lg border border-gray-700 hover:border-purple-400 transition-all duration-300 w-64">
      <div className="mb-4 text-purple-400 flex justify-center">
        {icon}
      </div>
      <h3 className="text-xl font-semibold mb-2 text-purple-400">{title}</h3>
      <p className="text-gray-400">{description}</p>
    </div>
  ))*/

  /*const CaseStudyCard = React.memo(({ study, onReadMore }) => (
    <div className="bg-gray-900 rounded-lg overflow-hidden shadow-lg transition-all duration-300 transform hover:scale-105">
      <img src={study.thumbnail} alt={study.title} className="w-full h-48 object-cover" />
      <div className="p-6">
        <img src={study.logo} alt={`${study.client} logo`} className="h-8 mb-4" />
        <h3 className="text-xl font-semibold mb-2 text-purple-400">{study.title}</h3>
        <p className="text-gray-400 mb-4">{study.overview}</p>
        <Button onClick={onReadMore} className="bg-purple-600 hover:bg-purple-700 text-white">
          Read More <ArrowRight className="ml-2 h-4 w-4" />
        </Button>
      </div>
    </div>
  ))*/

  const SocialIcon = React.memo(({ href, icon, name }) => (
    <a
      href={href}
      target="_blank"
      rel="noopener noreferrer"
      className="text-gray-400 hover:text-purple-400 transition-colors duration-300"
    >
      {icon}
      <span className="sr-only">{name}</span>
    </a>
  ))
 const Navigate = useNavigate();

 const handleSolutionsButton = () =>{
  Navigate('/oursolutions');
 }




  return (
    <>
    <GlobalStyle />
    <div className="min-h-screen bg-gray-900 text-gray-100 font-sans">
      {/* Navigation */}
      <nav className={`fixed w-full z-50 transition-all duration-300 ${isScrolled ? 'bg-gray-900 shadow-md' : 'bg-transparent'}`}>
  <div className="flex items-center justify-between h-20 w-full"> {/* Adjusted to px-6 for 24px padding on both sides */}
    
    <a href="/" className="flex items-center ">
      <img src={logo1} alt="Logo" className="h-60 w-auto" />
    </a>

    <div className="hidden md:flex justify-end items-center space-x-6 mr-3">
      <NavLink onClick={() => scrollTo(homeRef)}><p className='text-xl font-semibold'>Home</p></NavLink>
      <NavLink onClick={() => scrollTo(servicesRef)}><p className='text-xl font-semibold'>Services</p></NavLink>
      <NavLink onClick={() => scrollTo(aboutRef)}><p className='text-xl font-semibold'>About us</p></NavLink>
      {/*<NavLink onClick={() => scrollTo(successStoriesRef)}><p className='text-xl font-semibold'>Success Stories</p></NavLink>*/}
      <NavLink onClick={() => scrollTo(contactRef)}><p className='text-xl font-semibold'>Contact us</p></NavLink>
    </div>
  </div>
</nav>




      {/* Home Section */}
      <section ref={homeRef} className="min-h-screen flex items-center justify-center bg-gradient-to-br from-blue-900 via-purple-800 to-gray-900">
        <div className="text-center">
          <h1 className="text-5xl md:text-6xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-500 to-blue-500">
            Save money, time and labor<br />with our AI Automation systems
          </h1>
          <p className="text-xl mb-8 max-w-2xl mx-auto text-gray-300">
            Empowering businesses with cutting-edge AI automation to drive growth and efficiency.
          </p>
          <Button className="bg-purple-600 hover:bg-purple-700 text-white px-8 py-3 rounded-full text-lg font-semibold transition-all duration-300 ease-in-out transform hover:scale-105" onClick={handleSolutionsButton}>
            Discover Our Solutions
          </Button>
        </div>
      </section>

      {/* Services Section */}
      <section ref={servicesRef} className="py-24 bg-gray-800">
  <div className="container mx-auto px-4">
    <h2 className="text-4xl font-bold mb-12 text-center text-purple-400">Our AI Services</h2>
    <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-8">
      <ServiceCard
        icon={<Cpu className="w-12 h-12" />}
        title="AI Automation"
        description="Automate repetitive tasks and workflows, enhancing productivity and accuracy."
      />
      <ServiceCard
        icon={<BarChart className="w-12 h-12" />}
        title="Predictive Analytics"
        description="Use data to forecast trends and make proactive business decisions."
      />
      <ServiceCard
        icon={<MessageSquare className="w-12 h-12" />}
        title="Natural Language Processing"
        description="Enhance customer interactions with advanced language models."
      />
      <div className="col-span-full flex justify-center">
        <ServiceCard
          icon={<Puzzle className="w-12 h-12" />}
          title="Custom AI Solutions"
          description="Tailor-made AI applications designed to solve unique business challenges."
        />
      </div>
    </div>
  </div>
</section>


      {/* About Us Section */}
      <section ref={aboutRef} className="bg-gray-900 text-white py-24">
      <div className="container mx-auto px-4">
        <h2 className="text-3xl font-bold text-center text-purple-400 mb-12">Who We Are</h2>
        <div className="grid md:grid-cols-2 gap-96 mb-16 ">
          {founders.map((founder, index) => (
            <div key={index} className="flex items-center space-x-6">
              <div className="w-32 h-32 relative flex-shrink-0">
                <img
                  src={founder.image}
                  alt={founder.name}
                  className="w-full h-full object-cover absolute inset-0 rounded-full"
                />
                <div className="absolute inset-0 border-2 border-purple-500 rounded-full"></div>
              </div>
              <div>
                <h3 className="text-xl font-semibold text-purple-300">{founder.name}</h3>
                <p className="text-base text-gray-400 mb-2">{founder.role}</p>
                <strong className="text-sm text-gray-300">{founder.bio}</strong>
              </div>
            </div>
          ))}
        </div>

        <h3 className="text-2xl font-bold text-center text-purple-400 mb-8">Our Values</h3>
        <div className="grid md:grid-cols-3 gap-6 mb-16">
          {values.map((value, index) => (
            <div key={index} className="bg-gray-800 p-6 rounded-lg text-center">
              <div className="text-purple-400 mb-4 flex justify-center">{value.icon}</div>
              <h4 className="text-lg font-semibold mb-2">{value.title}</h4>
              <p className="text-sm text-gray-300">{value.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>

      {/* Success Stories Section */}
      {/* 
      <section ref={successStoriesRef} className="py-20 bg-gray-800">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-12 text-center text-purple-400">Success Stories</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-8 mb-12">
            {caseStudies.map((study) => (
              <CaseStudyCard
                key={study.id}
                study={study}
                onReadMore={() => setActiveCaseStudy(study)}
              />
            ))}
          </div>
          <div className="mt-20">
            <h3 className="text-3xl font-bold mb-8 text-center text-purple-400">Client Testimonials</h3>
            <div className="relative max-w-3xl mx-auto">
              <div className="bg-gray-900 p-8 rounded-lg shadow-lg">
                <blockquote className="text-xl italic mb-4">
                  "{testimonials[activeTestimonial].quote}"
                </blockquote>
                <p className="text-right">
                  <span className="font-semibold text-purple-400">{testimonials[activeTestimonial].name}</span>
                  <br />
                  <span className="text-gray-400">{testimonials[activeTestimonial].role}</span>
                </p>
              </div>
              <Button
                className="absolute top-1/2 left-0 transform -translate-y-1/2 -translate-x-full text-purple-400 hover:text-purple-300"
                onClick={() => setActiveTestimonial((prev) => (prev - 1 + testimonials.length) % testimonials.length)}
              >
                <ChevronLeft className="h-8 w-8" />
              </Button>
              <Button
                className="absolute top-1/2 right-0 transform -translate-y-1/2 translate-x-full text-purple-400 hover:text-purple-300"
                onClick={() => setActiveTestimonial((prev) => (prev + 1) % testimonials.length)}
              >
                <ChevronRight className="h-8 w-8" />
              </Button>
            </div>
          </div>
        </div>
      </section>
*/}
      {/* Contact Us Section */}
      <section ref={contactRef} className=" py-24 bg-gray-900">
        <div className="container mx-auto px-4">
          <h2 className="text-4xl font-bold mb-12 text-center text-purple-400">Get in Touch</h2>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-12">
            <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
             
                <form ref={form} onSubmit={sendEmail} className="space-y-6">
                <div>
                  <label htmlFor="from_name" className="block text-lg font-semibold text-gray-300">
                    Name
                  </label>
                  <input
                    type="text"
                    id="name"
                    name="from_name"
                    value={formData.from_name}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full rounded-md px-4 bg-gray-700 border-gray-600 text-white shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50 h-10"
                  />
                </div>
                <div>
                  <label htmlFor="from_email" className="block text-lg font-semibold text-gray-300">
                    Email
                  </label>
                  <input
                    type="email"
                    id="email"
                    name="from_email"
                    value={formData.from_email}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full rounded-md px-4 bg-gray-700 border-gray-600 text-white shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50 h-10"
                  />
                </div>
                <div>
                  <label htmlFor="from_company" className="block text-lg font-semibold text-gray-300">
                    Company
                  </label>
                  <input
                    type="text"
                    id="company"
                    name="from_company"
                    value={formData.from_company}
                    onChange={handleChange}
                    className="mt-1 block w-full rounded-md px-4 bg-gray-700 border-gray-600 text-white shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50 h-10"
                  />
                </div>
                <div>
                  <label htmlFor="message" className="block text-lg font-semibold text-gray-300">
                    Message
                  </label>
                  <textarea
                    id="message"
                    name="message"
                    rows="4"
                    value={formData.message}
                    onChange={handleChange}
                    required
                    className="mt-1 block w-full px-4 rounded-md bg-gray-700 border-gray-600 text-white shadow-sm focus:border-purple-500 focus:ring focus:ring-purple-500 focus:ring-opacity-50"
                  ></textarea>
                </div>
                <div>
                  <button
                    type="submit"
                    className="w-full flex justify-center py-2 px-4 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-purple-600 hover:bg-purple-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500"
                 
                  >
                    Send Message
                    <Send className="ml-4 h-5 w-5" />
                  </button>
                  {isOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center p-4">
          <div className="bg-gray-900 rounded-lg max-w-md w-full p-6 text-gray-100">
            <div className="text-center">
              <div className="flex items-center justify-center mb-4">
                <svg className="w-12 h-12 text-green-500" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 12l2 2 4-4m6 2a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
              </div>
              <h2 className="text-2xl font-bold mb-2 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-500 to-blue-500">
                Message Sent
              </h2>
              <p className="text-gray-300 mb-4">
                Your message has been successfully sent. We'll get back to you soon.
              </p>
            </div>
            <div className="text-center mb-6">
              <p className="text-gray-300">
                Thank you for reaching out to us. Our team will review your message and respond as soon as possible.
              </p>
            </div>
            <div className="flex justify-end">
              <button
                onClick={closeModal}
                className="bg-purple-600 hover:bg-purple-700 text-white px-4 py-2 rounded-full text-sm font-semibold transition-all duration-300 ease-in-out transform hover:scale-105"
              >
                Done
              </button>
            </div>
          </div>
        </div>
      )}
                </div>
              </form>
              
            </div>
            <div className="space-y-8">
              <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
                <h3 className="text-2xl font-bold mb-6 text-purple-400">Contact Information</h3>
                <div className="space-y-4">
                  <div className="flex items-center">
                    <Mail className="w-6 h-6 text-purple-400 mr-4" />
                    <span>botur@essentai.co</span>
                  </div>
                 
                 {/* <div className="flex items-center">
                    <MapPin className="w-6 h-6 text-purple-400 mr-4" />
                    <span>123 AI Avenue, New York, NY</span>
                  </div> */}
                </div>
              </div>
              <div className="bg-gray-800 p-8 rounded-lg shadow-lg">
                <h3 className="text-2xl font-bold mb-6 text-purple-400">Find Us</h3>
                <div className="aspect-w-16 aspect-h-9 mb-6">
                  <iframe
                  title='Our_map'
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d193595.25280949822!2d-74.11976379633536!3d40.69766374932912!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c24fa5d33f083b%3A0xc80b8f06e177fe62!2sNew%20York%2C%20NY!5e0!3m2!1sen!2sus!4v1679925500780!5m2!1sen!2sus"
                    width="100%"
                    height="100%"
                    style={{ border: 0 }}
                    allowFullScreen=""
                    loading="lazy"
                    referrerPolicy="no-referrer-when-downgrade"
                  ></iframe>
                </div>
                <div className="flex justify-center space-x-6">
                  <SocialIcon href="https://www.linkedin.com/company/essent-ai/posts/?feedView=all" icon={<Linkedin />} name="LinkedIn" />
                  <SocialIcon href="https://www.instagram.com/essent.ai?igsh=YWxkOXprMTZwZ3pp" icon={<Instagram/>} name="Instagram" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      
      <footer className="bg-gray-900 text-gray-300 py-12 border-t border-gray-800">
  <div className="container mx-auto px-4">
    <div className="flex flex-col md:flex-row justify-between gap-8"> {/* Changed to flexbox layout */}
      <div className="flex-1">
        <h3 className="text-xl font-bold mb-4 text-purple-400">Essent AI</h3>
        <p className="text-gray-400">Empowering businesses with AI solutions.</p>
      </div>

      <div className="flex-1">
        <h4 className="text-lg font-semibold mb-4 text-purple-400">Legal</h4>
        <ul className="space-y-2">
          <li><a href="/privacy" className="text-gray-400 hover:text-purple-400">Privacy Policy</a></li>
          <li><a href="/terms" className="text-gray-400 hover:text-purple-400">Terms of Service</a></li>
        </ul>
      </div>

      <div className="flex-1">
        <h4 className="text-lg font-semibold mb-4 text-purple-400">Newsletter</h4>
        <p className="text-gray-400 mb-4">Stay updated with our latest news and offers.</p>
        <form className="flex">
          <input type="email" placeholder="Your email" className="rounded-l-lg bg-gray-800 border-gray-700 text-gray-100 px-4 py-2 w-full" /> {/* Adjusted for width */}
          <button type="submit" className="bg-purple-600 hover:bg-purple-700 rounded-r-lg px-4 py-2"> {/* Adjusted button */}
            Subscribe
          </button>
        </form>
      </div>
    </div>
    <div className="mt-8 pt-8 border-t border-gray-800 text-center text-gray-400">
      <p>&copy; 2024 Essent AI. All rights reserved.</p>
    </div>
  </div>
</footer>

      {/* Detailed Case Study Modal */}
      {activeCaseStudy && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-gray-800 p-8 rounded-lg max-w-3xl max-h-[90vh] overflow-y-auto">
            <h2 className="text-3xl font-bold mb-4 text-purple-400">{activeCaseStudy.title}</h2>
            <img src={activeCaseStudy.logo} alt={`${activeCaseStudy.client} logo`} className="mb-4 h-12" />
            <h3 className="text-xl font-semibold mb-2">Challenge</h3>
            <p className="mb-4 text-gray-300">{activeCaseStudy.challenge}</p>
            <h3 className="text-xl font-semibold mb-2">Solution</h3>
            <p className="mb-4 text-gray-300">{activeCaseStudy.solution}</p>
            <h3 className="text-xl font-semibold mb-2">Results</h3>
            <p className="mb-4 text-gray-300">{activeCaseStudy.results}</p>
            <Button onClick={() => setActiveCaseStudy(null)} className="bg-purple-600 hover:bg-purple-700 text-white">
              Close
            </Button>
          </div>
        </div>
      )}
    </div>
    </>
  )
}

export default React.memo(EssentAISinglePage)