'use client'

import React, { useState, useRef } from 'react';
import { Cpu, BarChart, MessageSquare, Puzzle } from 'lucide-react';
import { useNavigate, Link } from 'react-router-dom';
import logo1 from './Images/7.png'


const OurSolutions = () => {
  const [activeTab, setActiveTab] = useState("ai-automation");
  const Navigate = useNavigate();


 



  const scrollTo = (ref) => {
    ref.current?.scrollIntoView({ behavior: 'smooth' })
  }
  const callRef = useRef(null)

  const Button = React.memo(({ children, onClick, className, type = "button" }) => (
    <button
      type={type}
      onClick={onClick}
      className={`px-4 py-2 rounded ${className}`}
    >
      {children}
    </button>
  ))

  const solutions = [
    {
      id: "ai-automation",
      title: "AI Automation",
      icon: <Cpu className="w-4 h-4" />,
      description: "Optimize workflows and enhance efficiency with intelligent AI-driven automation.",
      content: (
        <>
          <p className="mb-4">
            Our AI Automation solutions are designed to streamline business processes by leveraging advanced machine learning algorithms. These solutions automate repetitive, time-consuming tasks, freeing up human resources for more strategic activities and reducing the potential for manual errors. From decision-making systems to predictive maintenance, AI automation ensures that operations are not only faster but also more accurate.
          </p>
          <h4 className="text-lg font-semibold mb-2">Key Features:</h4>
          <ul className="list-disc list-inside mb-4">
            <li>End-to-end workflow optimization, reducing bottlenecks and inefficiencies</li>
            <li>Automated decision-making models that adapt to real-time data</li>
            <li>Seamless integration with Robotic Process Automation (RPA) tools</li>
            <li>Predictive maintenance solutions that help prevent downtime in industrial environments</li>
          </ul>
          <p>
            By integrating our AI Automation technologies, businesses can see up to a 40% increase in operational throughput, significantly reduce labor costs, and improve overall process reliability.
          </p>
        </>
      ),
    },
    {
      id: "predictive-analytics",
      title: "Predictive Analytics",
      icon: <BarChart className="w-4 h-4" />,
      description: "Make informed decisions by forecasting trends and outcomes using advanced data analysis.",
      content: (
        <>
          <p className="mb-4">
            Our Predictive Analytics solutions employ cutting-edge statistical modeling, data mining, and machine learning techniques to help businesses uncover hidden patterns in historical data. By understanding these trends, businesses can make proactive decisions that reduce risks and seize opportunities more effectively. Predictive analytics are invaluable for various industries, from retail and finance to healthcare and manufacturing.
          </p>
          <h4 className="text-lg font-semibold mb-2">Applications:</h4>
          <ul className="list-disc list-inside mb-4">
            <li>Sales forecasting to predict market demand and optimize inventory levels</li>
            <li>Customer churn prevention by identifying at-risk customers early</li>
            <li>Risk assessment and fraud detection using anomaly detection models</li>
            <li>Supply chain optimization to ensure timely deliveries and minimize disruptions</li>
          </ul>
          <p>
            Our clients have reported up to a 30% increase in the accuracy of their business forecasts, leading to better decision-making and up to a 25% reduction in operational costs through predictive insights.
          </p>
        </>
      ),
    },
    {
      id: "natural-language-processing",
      title: "ChatBots",
      icon: <MessageSquare className="w-4 h-4" />,
      description: "Transform customer engagement with state-of-the-art language processing technology.",
      content: (
        <>
          <p className="mb-4">
            Our NLP solutions empower businesses to interact with their customers in more meaningful ways by enabling computers to understand, process, and generate human language. Whether through sentiment analysis, automated customer service, or real-time language translation, our NLP models help businesses bridge the gap between human language and machine comprehension, leading to improved customer experiences and operational efficiency.
          </p>
          <h4 className="text-lg font-semibold mb-2">Key Capabilities:</h4>
          <ul className="list-disc list-inside mb-4">
            <li>Sentiment analysis to gauge customer emotions and feedback in real-time</li>
            <li>Chatbots and virtual assistants that provide instant, context-aware responses</li>
            <li>Automated content generation for marketing, reporting, and documentation</li>
            <li>Accurate language translation and localization for global operations</li>
          </ul>
          <p>
            Businesses using our NLP solutions have achieved up to a 60% reduction in customer service costs, while simultaneously improving customer satisfaction by up to 40% through more personalized and efficient interactions.
          </p>
        </>
      ),
    },
    
    {
      id: "custom-ai-solutions",
      title: "Custom AI Solutions",
      icon: <Puzzle className="w-4 h-4" />,
      description: "Tailor-made AI applications designed to solve your unique business challenges.",
      content: (
        <>
          <p className="mb-4">
            Our Custom AI Solutions are engineered to address specific business challenges that cannot be solved with standard off-the-shelf software. We collaborate closely with clients to design, develop, and deploy AI-powered systems that deliver measurable results, whether you're looking to automate quality control in manufacturing, implement a personalized recommendation system, or develop intelligent document processing tools for legal or financial sectors.
          </p>
          <h4 className="text-lg font-semibold mb-2">Examples of Custom AI Solutions:</h4>
          <ul className="list-disc list-inside mb-4">
            <li>AI-powered quality control systems that analyze products in real-time to detect defects</li>
            <li>Personalized recommendation engines that enhance user experiences and increase sales</li>
            <li>Intelligent document processing systems for legal, financial, and administrative tasks</li>
            <li>Predictive maintenance systems that prevent costly breakdowns in industrial settings</li>
          </ul>
          <p>
            Clients who have implemented our Custom AI Solutions have experienced up to a 55% improvement in operational efficiency, along with a 40% increase in customer engagement and satisfaction.
          </p>
        </>
      ),
    },
  ];
  
  const handleScheduleButton = () => {
    Navigate('/contactus')
  }

 
  return (
    <div className="min-h-screen bg-gray-900 text-gray-100">
      <header className="py-28 bg-gradient-to-br from-blue-900 via-purple-800 to-gray-900">
        <div className="container mx-auto px-4 text-center">
        <nav className="w-max bg-transparent">
  <div className="container mx-auto flex items-center justify-start h-1 mr-10">
    <a href="/" className="flex items-center">
      <img src={logo1} alt="Logo" className="h-56  w-auto mb-16" /> {/* Static logo size */}
    </a>
  </div>
</nav>


          <h1 className="text-4xl md:text-5xl font-bold mb-6 bg-clip-text text-transparent bg-gradient-to-r from-purple-400 via-pink-500 to-blue-500">
            Our AI Solutions
          </h1>
          <p className="text-xl text-gray-300 max-w-2xl mx-auto mb-8">
            Discover how our cutting-edge AI solutions can transform your business and drive innovation.
          </p>
          <div className="flex flex-col items-center space-y-4">
  <div className="inline-flex h-10 items-center justify-center rounded-xl bg-gray-800 p-1">
    {solutions.map((solution) => (
      <button
        key={solution.id}
        onClick={() => setActiveTab(solution.id)}
        className={`rounded-lg inline-flex items-center justify-center whitespace-nowrap px-3 py-1.5 text-sm font-medium transition-all focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:pointer-events-none disabled:opacity-50 ${
          activeTab === solution.id ? 'bg-purple-600 text-white' : 'text-gray-300 hover:bg-gray-700'
        }`}
      >
        {solution.icon}
        <span className="ml-2">{solution.title}</span>
      </button>
    ))}
  </div>
  <Button
    className="bg-purple-600 hover:bg-purple-700 text-white px-8 py-3 rounded-full text-lg font-semibold transition-all duration-300 ease-in-out transform hover:scale-105"
    onClick={() => scrollTo(callRef)}
  >
    Ready to Transform your Business?
  </Button>
</div>
</div>
      </header>
  
      <main className="container mx-auto px-4 py-12">
        {solutions.map((solution) => (
          activeTab === solution.id && (
            <div key={solution.id} className="bg-gray-800 border border-gray-700 rounded-lg overflow-hidden">
              <div className="p-6 text-left">
                <div className="flex items-center mb-4 text-purple-400 group-hover:text-purple-300 transition-colors duration-300">
                  <div className="mr-2">
                    {React.cloneElement(solution.icon, { className: "w-6 h-6" })}
                  </div>
                  <h2 className="text-2xl font-bold">{solution.title}</h2>
                </div>
                <p className="text-gray-300 font-normal mb-4">{solution.description}</p>
                <div className="text-white font-medium">
                  {solution.content}
                </div>
              </div>
            </div>
          )
        ))}
      </main>

      <section ref={callRef} className="bg-gray-800 py-20">
        <div className="container mx-auto px-4 text-center">
          <h2 className="text-3xl font-bold mb-6 text-purple-400">Ready to Transform Your Business?</h2>
          <p className="text-xl text-gray-300 mb-8 max-w-2xl mx-auto">
            Let's discuss how our AI solutions can address your specific business needs and drive growth.
          </p>
          <button className="bg-purple-600 hover:bg-purple-700 text-white px-8 py-3 rounded-full text-lg font-semibold transition-all duration-300 transform hover:scale-105" onClick={handleScheduleButton}>
            Schedule a Consultation
          </button>
          <Link to="/" className="text-purple-400 hover:text-purple-300 mt-6 block">
            Back to Home
          </Link>
        </div>
      </section>
  
      <footer className="bg-gray-900 text-gray-300 py-12 border-t border-gray-800">
        <div className="container mx-auto px-4 text-center">
          <p>&copy; 2024 Essent AI. All rights reserved.</p>
        </div>
      </footer>
    </div>
  );
}

export default OurSolutions;